import React, {useEffect, useState} from 'react';
import {
    Card,
    CardContent,
    Paper,
    Box,
    TextField,
    TablePagination, MenuItem, Button, Checkbox, FormControlLabel, useMediaQuery, Link,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from "../../style/style";
import api from "../../services/api";
import {makeStyles} from "@material-ui/styles";
import {searchQuestions, searchQuestionsPage} from "../../services/seacrhQuestions";
import QuestionItemList from "./QuestionItemList";
import useTypeOfEvaluations from "../../hooks/useTypeOfEvaluations";
import CustomSelect from "../CustomSelect";


const useStylesLocal = makeStyles(theme => ({
    textFoot: {
        color: '#000000', fontFamily: 'Verdana', fontSize: '12px', marginTop: '10px'
    },
    paper: {
        marginBottom: 10,
        '& > *': {
            margin: theme.spacing(2),
        },
        margin: 3,
        padding: 8
    },
    paperCorrect: {
        backgroundColor: '#e2f2e7',
        color: '#212121',
    },
    paperRightFont: {
        color: '#80cbc4',
    },
    paperWrongFont: {
        color: '#ef9a9a',
    },
}));

const QuestionList = ({ onQuestionsSelected, onClose }) => {
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [courses, setCourses] = useState([]);
    const [objects, setObjects] = useState([]);
    const [typeEvaluationSelect, setTypeEvaluationSelect] = useState(null);
    const [typeEvaluation, setTypeEvaluation] = useState(null);
    const [years, setYears] = useState(null);
    const [yearSelect, setYearSelect] = useState(null);
    const [courseSelect, setCourseSelect] = useState(null);
    const [objectSelect, setObjectSelect] = useState([]);
    const [filterByUser, setFilterByUser] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [questionsPerPageSelect, setQuestionsPerPageSelect] = useState(10);

    const [enableFilter, setEnableFilter] = React.useState(false);

    const classesGeneral = useStyles();
    const classes = useStylesLocal();
    const [questions, setQuestions] = useState(null);

    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const optionsQuestionsPerPagge = [
        { id: 5, description: '5' },
        { id: 10, description: '10' },
        { id: 20, description: '20' },
        { id: 30, description: '30' },
        { id: 40, description: '40' },
        { id: 50, description: '50' },
        { id: 100, description: '100' },
    ];

    async function loadCourses(){
        try {
            const response = await api.get('all/courses-user');
            setCourses([...courses, ...response.data]);

        } catch (error) {

        }
    }

    async function loadObjects(){
        try {
            const fk_course_id = courseSelect;
            const data = {
                fk_course_id
            }
            const response = await api.get('all/objects?fk_course_id='+courseSelect);

            setObjects([...response.data]);

        } catch (error) {

        }
    }

    async function loadTypeOfEvaluation(){
        try {
            const fk_course_id = courseSelect;
            const data = {
                fk_course_id
            }
            const response = await api.get('all/type-of-evaluations-by-area?fk_course_id='+courseSelect);

            setTypeEvaluation([...response.data]);

        } catch (error) {

        }
    }

    async function loadYear(){
        try {
            const fk_course_id = courseSelect;
            const data = {
                fk_course_id
            }
            const response = await api.get('all/year-by-area?fk_course_id='+courseSelect);

            setYears(response.data);

        } catch (error) {

        }
    }

    async function loadQuestions(page){
        try {
            let url = 'question?page='+page;

            if(filterByUser){
                url += '&user=S';
            } else {
                url += '&user=T';
            }

            if(courseSelect && courseSelect > 0){
                url += '&fk_course_id='+courseSelect;
            }

            if(objectSelect && objectSelect > 0){
                url += '&fk_object_id='+objectSelect;
            }

            if(typeEvaluationSelect && typeEvaluationSelect > 0){
                url += '&fk_type_of_evaluation_id='+typeEvaluationSelect;

            }

            if(yearSelect && yearSelect > 0){
                url += '&year='+yearSelect;
            }

            if(questionsPerPageSelect > 0){
                url += '&totalPerPage='+questionsPerPageSelect;
            }

            const response = await api.get(url);
            if(response.status == 200) {
                setTotal(response.data.total);
                setQuestions(response.data.data);
                setRowsPerPage(response.data.per_page);
            } else {
                setQuestions([]);
            }
        } catch (error) {

        }
    }

    const handleSelectQuestion = (id) => {
        const updatedSelection = selectedQuestions.includes(id)
            ? selectedQuestions.filter((questionId) => questionId !== id)
            : [...selectedQuestions, id];
        setSelectedQuestions(updatedSelection);
        onQuestionsSelected(updatedSelection); // Chama o callback para retornar as questões selecionadas
    };

    useEffect(() => {
        if(localStorage.getItem('@Questione-search-course') != null){
            setCourseSelect(localStorage.getItem('@Questione-search-course'));
        }
        if(localStorage.getItem('@Questione-search-object') !== 0){
            setObjectSelect(localStorage.getItem('@Questione-search-object'));
        }
        if(localStorage.getItem('@Questione-search-type-evaluation') !== ''){
            setTypeEvaluationSelect(localStorage.getItem('@Questione-search-type-evaluation'));
        }
        if(localStorage.getItem('@Questione-search-year-question') !== ''){
            setYearSelect(localStorage.getItem('@Questione-search-year-question'));
        }
        if(localStorage.getItem('@Questione-total-per-page') != ''){
            setQuestionsPerPageSelect(localStorage.getItem('@Questione-total-per-page'));
        }

        loadQuestions(1);
    }, [courses]);

    useEffect(() => {
        loadQuestions(page+1);
        loadCourses();
    }, []);

    useEffect(() => {
        if(courseSelect > 0) {
            loadObjects();
            loadTypeOfEvaluation();
            loadYear();
            setEnableFilter(true);
        }
    }, [courseSelect]);

    const handlePageChange = (event, newPage) => {
        loadQuestions(newPage+1);
        setPage(newPage);
    };

    const handleRowsPerPageChange = event => {
        setRowsPerPage(event.target.value);
    };

    const onChangeObject = (e) =>{
        setObjectSelect(e.target.value);
    }

    const onChangeCourse = (e) =>{
        setCourseSelect(e.target.value);
        setObjectSelect(null);
        if(e.target.value > 0 ){
           setEnableFilter(true);
        }
    }

    const onClickSearch = (e) => {
        setPage(0);
        loadQuestions(1);
        searchQuestionsPage(0);

        localStorage.setItem('@Questione-search-course', courseSelect);
        localStorage.setItem('@Questione-search-object', objectSelect);
        localStorage.setItem('@Questione-search-type-evaluation', typeEvaluationSelect);
        localStorage.setItem('@Questione-search-year-question', yearSelect);
        localStorage.setItem('@Questione-total-per-page', questionsPerPageSelect);
    }

    const onToggleFilterByUser = (event) => {
        setFilterByUser(event.target.checked); // Atualiza o estado do checkbox
    };

    const handleTypeEvaluation = (event) =>{
        setTypeEvaluationSelect(event.target.value);
    }

    const handleToggleSelectAll = () => {
        setSelectAll(!selectAll);
    };

    const handleChangeQuestionsPerPage = (e) => {
        setQuestionsPerPageSelect(e.target.value);
    };

    const onChangeYear = (e) =>{
        setYearSelect(e.target.value);
    }

    const onClean = () =>{
        setCourseSelect(0);
        setObjectSelect(0);
        setTypeEvaluationSelect(0);
        setYearSelect(0);
        setEnableFilter(false);
    }

    return (
        <div>
            <Paper style={{ padding: '10px' }}>
                <Box display="flex" flexDirection="column" marginTop='20px'>
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        marginBottom="15px">
                        <Box style={{flex: '1 1 200px', marginRight: '10px'}}>
                            <CustomSelect
                                text="Área"
                                value={courseSelect}
                                onChange={onChangeCourse}
                                textOption="Todas as áreas"
                                visibility={true}
                                width="100%"
                                valuesMap={courses}
                            />
                        </Box>


                        <Box style={{ flex: '1 1 200px', marginRight: '10px', marginTop: isSmallScreen ? '10px' : '0px' }}>
                                <CustomSelect
                                    text="Conteúdo"
                                    value={objectSelect}
                                    onChange={onChangeObject}
                                    visibility={enableFilter}
                                    textOption="Todos os conteúdos"
                                    width="100%"
                                    valuesMap={objects}
                                />
                        </Box>


                        <Box style={{ flex: '1 1 200px', marginRight: '10px', marginTop: isSmallScreen ? '10px' : '0px' }}>
                                <CustomSelect
                                    text="Banca"
                                    value={typeEvaluationSelect}
                                    onChange={handleTypeEvaluation}
                                    visibility={enableFilter}
                                    textOption="Todos"
                                    width="100%"
                                    valuesMap={typeEvaluation}
                                />
                        </Box>


                        <Box style={{ flex: '1 1 200px', marginTop: isSmallScreen ? '10px' : '0px' }}>
                            <CustomSelect
                                text="Ano"
                                value={yearSelect}
                                onChange={onChangeYear}
                                visibility={enableFilter}
                                textOption="Todos"
                                width="100%"
                                valuesMap={years}
                            />
                        </Box>
                    </Box>

                    <Box display="flex" justifyContent={isSmallScreen ? "center" : "flex-start"} flexDirection={isSmallScreen ? "column" : "row"} style={{marginTop: '5px'}}>
                        <Box display="flex" style={{flex: '1 1 30px', marginBottom: '5px'}}>
                            <div className="mb-3" style={{display: 'flex'}}>
                                <label htmlFor="type-of-evaluation" className="form-label" style={{marginTop: '6px'}}>
                                    {"Total por Página"}
                                </label>
                                <select
                                    id={"select-total-page"}
                                    className="form-select"
                                    value={questionsPerPageSelect}
                                    onChange={handleChangeQuestionsPerPage}
                                    aria-describedby={"select-total-page"}
                                    style={{width: "100px", marginLeft: '10px'}}>
                                    <option value={0}>Selecione</option>
                                    {optionsQuestionsPerPagge && optionsQuestionsPerPagge.map((type) => (
                                        <option key={type.id ? type.id : type} value={type.id ? type.id : type}>
                                            {type.description ? type.description : type}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </Box>
                        <Box display="flex" justifyContent={"center"} style={{ flex: '1 1 50px' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filterByUser}
                                        onChange={onToggleFilterByUser}
                                        name="filterByUser"
                                    />
                                }
                                label={isSmallScreen ? "Apenas minhas questões" : "Mostrar apenas as minhas questões"}
                            />
                        </Box>
                    </Box>

                    <Box display="flex" justifyContent={isSmallScreen ? "center" : "centerd"}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classesGeneral.buttons}
                            onClick={onClickSearch}
                            style={{ height: '35px' }}>
                            Filtrar questões
                        </Button>
                        <div style={{marginLeft: '10px'}}>
                            <Button variant="contained"className={classesGeneral.buttons} onClick={onClean} style={{ height: '35px' }}>
                                {isSmallScreen ? "Limpar" : "Limpar Filtro"}
                            </Button>
                        </div>
                    </Box>
                </Box>
                <Box display={"flex"} justifyContent={"center"}>
                    {selectedQuestions.length > 0  &&
                            <Link
                                component="button"
                                variant="body2"
                                onClick={onClose}
                                style={{ display: 'block', marginTop: '20px', cursor: 'pointer', color: 'blue', fontSize: '15px' }}>
                                Clique aqui para adicionar as questões selecionadas {"("+selectedQuestions.join(', ')+")."}
                            </Link>
                        }
                </Box>
            </Paper>

            <TablePagination
                component="div"
                count={total}
                onChangePage={handlePageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[rowsPerPage]}/>

            {questions != null ? questions.map(question => (
                        <Card key={question.id} style={{ margin: '10px 0' }}>
                            <CardContent>
                                <QuestionItemList
                                    question={question}
                                    selectedQuestions={selectedQuestions}
                                    handleSelectQuestion={handleSelectQuestion}
                                    totalCharactersInText = {150}
                                    showQuestionsDefault={false}
                                    selectAll={selectAll}
                                    />
                            </CardContent>
                        </Card>
                    )) : null}

            <TablePagination
                component="div"
                count={total}
                onChangePage={handlePageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[rowsPerPage]}/>

            {/*<div style={{marginBottom: '20px'}}>
                <QuestionCard
                    question={question}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    id_course={searchText[1].fk_course_id}/>
            </div>*/}
        </div>
    );
};

QuestionList.propTypes = {
    onQuestionsSelected: PropTypes.func.isRequired, // Adiciona propTypes para o callback
};

export default QuestionList;
