import React, {useEffect, useState} from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const BarChart = ({ data, content }) => {
    const [sortedData, setSortedData] = useState([]);

    useEffect(() => {
        // Ordena os dados por percentage_correct em ordem crescente
        const orderedData = [...data].sort((a, b) => b.percentage_correct - a.percentage_correct);
        setSortedData(orderedData);
    }, [data]);

    // Configuração das cores das barras com base na porcentagem
    const getColor = (percentage) => {
        if (percentage < 30) return '#ff4569';
        if (percentage < 70) return '#ffef62';
        return '#33eb91';
    };

    // Preparando os dados para o gráfico
    const chartData = {
        labels: sortedData.map(d => d.decomposition.description),
        datasets: [{
            data: sortedData.map(d => d.percentage_correct),
            backgroundColor: sortedData.map(d => getColor(d.percentage_correct)),
            borderColor: 'black',
            borderWidth: 0.5,
        }]
    };

    // Configurações do gráfico
    const config = {
        type: 'bar',
        data: chartData,
        options: {
            indexAxis: 'y',
            elements: {
                bar: {
                    borderWidth: 2,
                }
            },
            responsive: true,
            plugins: {
                legend: {
                    display: false, // Oculta a legenda padrão
                },
                title: {
                    display: true,
                    text: 'Porcentagem de acerto dos assuntos de ' + content,
                    font: {
                        size: 22,
                        weight: 'bold',
                    },
                    padding: {
                        bottom: 20
                    }
                },
                datalabels: {
                    color: 'black',
                    anchor: 'end', // Alinha o texto ao final da barra
                    align: 'end', // Alinha o texto ao início da barra
                    formatter: (value, context) => {
                        const label = context.chart.data.labels[context.dataIndex];
                        const totalQuestions = data[context.dataIndex].total_questions;
                        const questionLabel = totalQuestions === 1 ? 'questão' : 'questões';
                        return `${label} (${totalQuestions.toString().padStart(2, '0')} ${questionLabel})\n${value}%`;
                    },
                    font: {
                        weight: 'bold',
                        size: 12,
                    },
                    padding: {
                        start: 10, // Ajusta o espaçamento para garantir que o texto comece a partir do início do eixo Y
                    },
                    clip: false, // Permite que o texto ultrapasse os limites do gráfico
                    overflow: 'visible', // Garante que o texto seja visível mesmo fora da área visível
                }
            },
            scales: {
                x: {
                    beginAtZero: true,
                    min: 0,
                    max: 100,
                },
                y: {
                    beginAtZero: true,
                    ticks: {
                        padding: 5, // Ajusta o espaçamento das labels do eixo Y
                    }
                }
            }
        },
    };

    return (
        <div>
            <Bar {...config} />
            <div style={{
                display: 'flex',            // Usa flexbox para alinhar o conteúdo
                justifyContent: 'center',  // Centraliza horizontalmente
                alignItems: 'center',      // Centraliza verticalmente (opcional)
                marginTop: '20px',
            }}>
                <div style={{
                    border: '2px solid black',
                    padding: '15px',
                    display: 'inline-block',
                    maxWidth: '100%'
                }}>
                    <div style={{fontSize: '14px', fontWeight: "bold"}}>Legenda de Cores:</div>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap', // Permite que os itens se movam para a linha seguinte se necessário
                        gap: '10px',     // Espaçamento entre os itens
                        alignItems: 'center'
                    }}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{
                                width: '20px',
                                height: '20px',
                                backgroundColor: '#ff4569',
                                marginRight: '10px'
                            }}></div>
                            <span style={{fontSize: '14px'}}>Acertos de 0% - 29%</span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{
                                width: '20px',
                                height: '20px',
                                backgroundColor: '#ffef62',
                                marginRight: '10px'
                            }}></div>
                            <span style={{fontSize: '14px'}}>Acertos de 30% - 69%</span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{
                                width: '20px',
                                height: '20px',
                                backgroundColor: '#33eb91',
                                marginRight: '10px'
                            }}></div>
                            <span style={{fontSize: '14px'}}>Acertos de 70% - 100%</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default BarChart;
