import React from 'react';

const CustomSelect = ({ text, textOption, value, onChange, valuesMap, width, visibility }) => {
    return (
        <div className="mb-3" style={{ display: visibility ? 'inline' : 'none'}}>
            <label htmlFor="type-of-evaluation" className="form-label">
                {text}
            </label>
            <select
                id={"select-"+text}
                className="form-select"
                value={value}
                onChange={onChange}
                aria-describedby={"select-"+text}
                style={{ width: width}}>
                <option value={0}>{textOption}</option>
                {valuesMap && valuesMap.map((type) => (
                    <option key={type.id ? type.id : type} value={type.id ? type.id : type}>
                        {type.description ? type.description : type}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default CustomSelect;
