import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadarController, RadialLinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(RadarController, RadialLinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const RadarChart = ({ data, studentData, content, width = 400, height = 400 }) => {
    // Calcular a média da turma por conteúdo
    const calculateAverage = (data) => {
        const contentMap = new Map();
        data.forEach(item => {
            const { description } = item;
            if (!contentMap.has(description)) {
                contentMap.set(description, []);
            }
            contentMap.get(description).push(item.percentage_correct);
        });


        const averageMap = new Map();
        contentMap.forEach((percentages, description) => {
            const average = percentages.reduce((sum, percentage) => sum + percentage, 0) / percentages.length;
            averageMap.set(description, average);
        });

        return averageMap;
    };

    const averageMap = calculateAverage(data);

    // Preparar os dados para o gráfico
    const chartData = {
        labels: [...averageMap.keys()], // Usando as descrições como rótulos
        datasets: [
            {
                label: 'Média do Aluno',
                data: [...averageMap.keys()].map(description => {
                    const studentItem = studentData.content.find(item => item.description === description);
                    return studentItem ? studentItem.percentage_correct : 0;
                }),
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
            {
                label: 'Média da Turma',
                data: [...averageMap.keys()].map(description => averageMap.get(description)),
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
        ],
    };

    // Configurações do gráfico
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: content,
            },
        },
        scales: {
            r: {
                angleLines: {
                    display: false,
                },
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                    display: true,
                    callback: (value) => {
                        // Exibir apenas 0 e 100, ocultar outros valores
                        if (value === 60 ||  value === 100) {
                            return value;
                        }
                        return '';
                    },
                },
            },
        },
    };

    return (
        <div style={{ width: `${width}px`, height: `${height}px` }}>
            <Radar data={chartData} options={options} />
        </div>
    );
};

export default RadarChart;
