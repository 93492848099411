import React from 'react';
import { Box, Breadcrumbs, Link, Typography } from '@material-ui/core';
import CharmHome from '@material-ui/icons/Home';

const BreadcrumbsComponent = ({ items }) => {
    return (
        <Box display="flex">
            <Breadcrumbs aria-label="breadcrumb">
                {items.map((item, index) => (
                    <React.Fragment key={index}>
                        {item.href ? (
                            <Link color="inherit" href={item.href}>
                                <Box display="flex">
                                    {item.icon && (
                                        <Box style={{ marginRight: '5px' }}>
                                            {item.icon}
                                        </Box>
                                    )}
                                    <Box>{item.label}</Box>
                                </Box>
                            </Link>
                        ) : (
                            <Typography color="textPrimary" key={index}>
                                {item.label}
                            </Typography>
                        )}
                    </React.Fragment>
                ))}
            </Breadcrumbs>
        </Box>
    );
};

export default BreadcrumbsComponent;
