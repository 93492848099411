import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
   Paper,
  Box, Tooltip,LinearProgress, Grid, Button, Link,
} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles";
import useStyles from "../../../../../../style/style";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import BarChart from "./BarChart/BarChart";

const useStylesLocal = makeStyles(() => ({
  root: {
    margin: '5px'
  },
  content: {
    padding: 0
  },
  percentageRed: {
    backgroundColor: '#EC0B43',
    display: 'block',
    margin: '8px',
    padding: '0 4px',
    textAlign: 'center',
    color: '#fff',
    borderRadius: 4
  },
  percentageOrange: {
    backgroundColor: '#F5A623',
    display: 'block',
    margin: '8px',
    padding: '0 4px',
    textAlign: 'center',
    color: '#fff',
    borderRadius: 4
  },
  percentageGreen: {
    backgroundColor: '#5DE2A5',
    display: 'block',
    margin: '8px',
    padding: '0 4px',
    textAlign: 'center',
    color: '#fff',
    borderRadius: 4
  },
  paperWrong: {
    width: '88%',
    backgroundColor: '#ef9a9a',
    color: '#212121',
    margin: 3,
    padding: 8
  },
  paperRight: {
    width: '88%',
    backgroundColor: '#80cbc4',
    color: '#212121',
    margin: 3,
    padding: 8
  },
  checkedCancel: {
    color: '#f44336'
  },
  lineQuestion: {
    marginLeft: 20,
  },
  labelRed: {
    backgroundColor: '#EC0B43',
    display: 'block',
    margin: '10px',
    padding: '5px',
    textAlign: 'center',
    color: '#fff',
    borderRadius: 4
  },
}));

const TooltipCustomized = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const EvaluationApplicationResultsSkillObjects = props => {
  const { className, history, result, objects, skills, ...rest } = props;
  const [ value, setValue] = React.useState(0);
  const [open, setOpen] = useState(false);

  const classes = useStylesLocal();
  const classesGeneral = useStyles();

  const handleOpen = (id) => {
    setOpen(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {

  }, []);

  const data = [
    { description: "Desequilibrios Ambientais", percentage_correct: 70 },
    { description: "Desequilibrios Ambientais", percentage_correct: 70 },
    { description: "Desequilibrios Ambientais", percentage_correct: 70 },
    { description: "Desequilibrios Ambientais", percentage_correct: 70 },
    { description: "Desequilibrios Ambientais", percentage_correct: 50 },
    { description: "Desequilibrios Ambientais", percentage_correct: 50 },
    { description: "Bioquímica Celular", percentage_correct: 48.08 },
    { description: "Bioquímica Celular", percentage_correct: 48.08 },
    { description: "Bioquímica Celular", percentage_correct: 48.08 },
    { description: "Bioquímica Celular", percentage_correct: 48.08 },
    { description: "Bioquímica Celular", percentage_correct: 48.08 },
    { description: "Principais Doenças", percentage_correct: 42.31 },
    { description: "Principais Doenças", percentage_correct: 42.31 },
    { description: "Cadeias e Teias Alimentares", percentage_correct: 23.08 }
  ];

  return (
      <div>
        <div>
          <Grid container >
            {objects == null ?
                <LinearProgress color="secondary"    />
                :
                objects.length == 0 ?
                <span className={classes.labelRed}>As questões desta avaliação não possuem conteúdos associados.</span>
                :
              objects.map((result, i) => (
                  <Grid item xs={12} sm={12} md={6} lg={4} key={i}>

                    <Paper
                           style={{height: '160px', paddingTop: '10px', paddingLeft: '10px', marginRight: '8px', marginBottom: '8px',
                             background: result.percentage_correct < 30 ? '#ffcdd2'
                                 : result.percentage_correct < 70 ? '#ffe0b2'
                                     : '#c8e6c9' }}>
                        <Box>
                          <div className={classesGeneral.paperTitleText} style={{fontSize: '19px'}}>
                            {result.description }
                          </div>
                          <div className={classesGeneral.paperTitleText} style={{fontSize: '11px'}}>
                            {result.course }
                          </div>
                          {result.decompositions && result.decompositions.length > 0 ? (
                              <Link component="button" onClick={() => handleOpen(result.idObject)} style={{ cursor: 'pointer', fontSize: '14px' }}>
                                Clique aqui para ver mais informações.
                              </Link>
                          ) : null}
                        </Box>

                        <Box display='flex' alignSelf={'flex-end'}>
                            <Box display='flex' flexGrow={1}>
                              <Box style={{marginTop: '15px'}}>
                                <div className={classesGeneral.paperTitleText} style={{fontSize: '14px'}}>
                                  {result.total_questions+' questões no simulado.'}
                                </div>
                                <div className={classesGeneral.paperTitleText} style={{fontSize: '14px'}}>
                                  {!result.total_answer ? 'Nenhum estudante respondeu.' :
                                    (result.total_answer) == 1 ? (result.total_answer)+' estudante respondeu.' : (result.total_answer)+' estudantes responderam.'}
                                </div>
                              </Box>
                            </Box>

                            <Box display='flex' style={{margin:'10px', justifyContent: 'center'}}>
                               <Box>
                                <div className={classesGeneral.paperTitleTextBold} style={{fontSize: '20px'}}>
                                  {result.percentage_correct+'%'}
                                </div>
                                <div className={classesGeneral.paperTitleText}>
                                  {'Acertaram'}
                                </div>
                              </Box>
                            </Box>
                        </Box>
                    </Paper>
                    {/* Dialog com mais informações */}
                    <Dialog open={open === result.idObject} onClose={handleClose} fullWidth maxWidth="md">
                      <DialogContent>
                        {/* Aqui você pode renderizar mais detalhes sobre o resultado */}
                        <BarChart data={result.decompositions} content={result.description}/>

                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          Fechar
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
            ))}
          </Grid>
        </div>

      </div>
  );
};

EvaluationApplicationResultsSkillObjects.propTypes = {
  className: PropTypes.string,
  skills: PropTypes.array.isRequired,
  objects: PropTypes.array.isRequired,
};

export default EvaluationApplicationResultsSkillObjects;
