// utils/formatName.js
export const formatStudentName = (fullName) => {
  // Divide o nome completo em partes
  const nameParts = fullName.split(' ');

  // Pega o primeiro e o segundo nomes e transforma em maiúsculas
  const firstName = nameParts[0];
  const secondName = nameParts[1];

  // Pega as iniciais dos sobrenomes após o segundo nome e as transforma em maiúsculas
  const initials = nameParts.slice(2).map(name => name.charAt(0).toUpperCase()).join('. ');

  // Retorna o formato desejado
  return initials ? `${firstName} ${secondName} ${initials}.` : `${firstName} ${secondName}`;
};