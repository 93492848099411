import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Tooltip, Slider } from "@material-ui/core";

const ScreenCaptureModal = ({ show, handleClose, onImageCaptured }) => {
    const [stream, setStream] = useState(null);
    const [screenshot, setScreenshot] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [cropSize, setCropSize] = useState({ width: 300, height: 200 }); // Novo estado para tamanho de corte
    const videoRef = useRef(null);

    useEffect(() => {
        if (show) {
            setScreenshot(null);
            setStream(null); 
        }
    }, [show]);

    const startCapture = async () => {
        try {
            const mediaStream = await navigator.mediaDevices.getDisplayMedia({
                video: {
                    cursor: 'always',
                },
            });
            setStream(mediaStream);
            videoRef.current.srcObject = mediaStream;
            videoRef.current.play();

            videoRef.current.onloadeddata = async () => {
                const canvas = document.createElement('canvas');
                canvas.width = videoRef.current.videoWidth;
                canvas.height = videoRef.current.videoHeight;
                const context = canvas.getContext('2d');
                context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
                const imgData = canvas.toDataURL('image/png');
                setScreenshot(imgData);

                if (stream) {
                    const tracks = stream.getTracks();
                    tracks.forEach((track) => track.stop());
                    setStream(null);
                }
            };
        } catch (err) {
            console.error('Erro ao capturar a tela: ' + err);
        }
    };

    const getCroppedImg = useCallback((imageSrc, crop) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = imageSrc;
            image.onload = () => {
                const canvas = document.createElement('canvas');
                const scaleX = image.naturalWidth / image.width;
                const scaleY = image.naturalHeight / image.height;
                canvas.width = crop.width;
                canvas.height = crop.height;
                const ctx = canvas.getContext('2d');

                ctx.drawImage(
                    image,
                    crop.x * scaleX,
                    crop.y * scaleY,
                    crop.width * scaleX,
                    crop.height * scaleY,
                    0,
                    0,
                    crop.width,
                    crop.height
                );

                canvas.toBlob((blob) => {
                    if (!blob) {
                        console.error('Canvas is empty');
                        return;
                    }
                    const url = window.URL.createObjectURL(blob);
                    resolve(url);
                }, 'image/jpeg');
            };
            image.onerror = (error) => reject(error);
        });
    }, []);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImageUrl = await getCroppedImg(screenshot, croppedAreaPixels);
            setCroppedImage(croppedImageUrl);
            onImageCaptured(croppedImageUrl); // Passa a imagem cortada para o componente pai
        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels, screenshot, onImageCaptured]);

    const handleWidthChange = (event, newWidth) => {
        setCropSize((prev) => ({ ...prev, width: newWidth }));
    };

    const handleHeightChange = (event, newHeight) => {
        setCropSize((prev) => ({ ...prev, height: newHeight }));
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Captura de Tela</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!screenshot ? (
                    <>
                        <Button variant="primary" onClick={startCapture} className="mb-3">
                            Iniciar Captura
                        </Button>
                        {stream && (
                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                <video ref={videoRef} style={{ maxWidth: '100%' }} />
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div style={{ position: 'relative', width: '100%', height: 400 }}>
                            <Cropper
                                image={screenshot}
                                crop={crop}
                                zoom={zoom}
                                aspect={cropSize.width / cropSize.height} // Atualiza o aspecto com base no tamanho do corte
                                cropSize={cropSize} // Passa o tamanho de corte atualizado
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onCropComplete={onCropComplete}
                            />
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                            <div className="d-flex">
                                <Tooltip title="Diminuir o zoom" arrow>
                                    <button
                                        className="btn btn-secondary mx-2"
                                        onClick={() => setZoom((prevZoom) => Math.max(prevZoom - 0.1, 1))}
                                        style={{ fontSize: '24px', width: '50px', height: '50px' }}
                                    >
                                        <i className="fa-solid fa-magnifying-glass-minus"></i>
                                    </button>
                                </Tooltip>
                                <Tooltip title="Aumentar o zoom" arrow>
                                    <button
                                        className="btn btn-secondary mx-2"
                                        onClick={() => setZoom((prevZoom) => prevZoom + 0.1)}
                                        style={{ fontSize: '24px', width: '50px', height: '50px' }}
                                    >
                                        <i className="fa-solid fa-magnifying-glass-plus"></i>
                                    </button>
                                </Tooltip>
                            </div>
                            <Button
                                variant="primary"
                                onClick={showCroppedImage}
                                className="mt-3"
                            >
                                Cortar Imagem
                            </Button>
                        </div>
                        {/* Sliders para ajustar a largura e a altura */}
                        <div className="mt-3">
                            <div className="mb-3">
                                <label>Largura de corte:</label>
                                <Slider
                                    value={cropSize.width}
                                    min={50}
                                    max={500}
                                    step={10}
                                    onChange={handleWidthChange}
                                    aria-labelledby="width-slider"
                                />
                            </div>
                            <div>
                                <label>Altura de corte:</label>
                                <Slider
                                    value={cropSize.height}
                                    min={50}
                                    max={500}
                                    step={10}
                                    onChange={handleHeightChange}
                                    aria-labelledby="height-slider"
                                />
                            </div>
                        </div>
                    </>
                
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ScreenCaptureModal;
