import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Table,
  TableBody,
  LinearProgress,
  TablePagination, Grid
} from '@material-ui/core';
import api from '../../../../services/api';
import UsersToolbar from "./components/QuestionToolbar";
import PropTypes from "prop-types";
import QuestionCard from "../../../../components/QuestionCard/QuestionCard";
import {QUESTION_SEARCH_SKILL, searchQuestions, searchQuestionsPage} from "../../../../services/seacrhQuestions";
import useStyles from "../../../../style/style";

const QuestionTable = props => {
  const { className, history } = props;

  const [questions, setQuestions] = useState(null);

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState([]);
  const [refresh, setRefresh] = React.useState(0);
  const [page, setPage] = useState(0);

  async function loadQuestions(page){
    try {
      let url = 'question?page='+page;

      let QUESTION_SEARCH_TYPE = "";
      if(searchText[0].value){
        url += '&user='+searchText[0].value;
        QUESTION_SEARCH_TYPE = searchText[0].value;
      }
      let QUESTION_SEARCH_COURSE = 0;
      let QUESTION_SEARCH_OBJECT = 0;
      let QUESTION_TYPE_EVALUATION_ID = 0;
      let QUESTION_YEAR = 0;
      let TOTAL_PER_PAGE = 0;
      if(searchText[0].totalPerPage > 0){
        url += '&totalPerPage='+searchText[0].totalPerPage;
        TOTAL_PER_PAGE = searchText[0].totalPerPage;
      }
      if(searchText[0].fk_course_id > 0){
        url += '&fk_course_id='+searchText[0].fk_course_id;
        QUESTION_SEARCH_COURSE = searchText[0].fk_course_id;

        if(searchText[0].fk_object_id > 0){
          url += '&fk_object_id='+searchText[0].fk_object_id;
          QUESTION_SEARCH_OBJECT = searchText[0].fk_object_id;
        }

        if(searchText[0].year > 0){
          url += '&year='+searchText[0].year;
          QUESTION_YEAR = searchText[0].year;
        }

        if(searchText[0].fk_type_of_evaluation_id > 0){
          url += '&fk_type_of_evaluation_id='+searchText[0].fk_type_of_evaluation_id;
          QUESTION_TYPE_EVALUATION_ID = searchText[0].fk_type_of_evaluation_id;
        }

      }

      const response = await api.get(url);
      if(response.status == 200) {
        searchQuestions(QUESTION_SEARCH_TYPE, QUESTION_SEARCH_COURSE, QUESTION_SEARCH_OBJECT, QUESTION_TYPE_EVALUATION_ID,
            QUESTION_YEAR, TOTAL_PER_PAGE);
        setTotal(response.data.total);
        setRowsPerPage(response.data.per_page);
        setQuestions(response.data.data);
      } else {
        setQuestions([]);
      }
    } catch (error) {
      //toast.error( 'Erro de conexão.');
    }
  }

  useEffect(() => {
    if(localStorage.getItem('@Questione-search-type') != null){
      searchText[0].value = localStorage.getItem('@Questione-search-type');
    }
    if(localStorage.getItem('@Questione-search-course') != 0){
      searchText[0].fk_course_id = localStorage.getItem('@Questione-search-course');
    }
    if(localStorage.getItem('@Questione-search-object') != 0){
      searchText[0].fk_object_id = localStorage.getItem('@Questione-search-object');
    }
    if(localStorage.getItem('@Questione-search-type-evaluation') != ''){
      searchText[0].fk_type_of_evaluation_id = localStorage.getItem('@Questione-search-type-evaluation');
    }
    if(localStorage.getItem('@Questione-search-year-question') != ''){
      searchText[0].year = localStorage.getItem('@Questione-search-year-question');
    }
    if(localStorage.getItem('@Questione-total-per-page') != ''){
      searchText[0].totalPerPage = localStorage.getItem('@Questione-total-per-page');
    }
    if(localStorage.getItem('@Questione-search-page') != 0){
      handlePageChange(null,  Number(localStorage.getItem('@Questione-search-page')));
    }

    loadQuestions(page+1);
  }, [refresh]);

  const updateSearch = (e) => {
    setSearchText(e.target.value);
  }

  const onClickSearch = (e) => {
    setPage(0);
    loadQuestions(1);
    searchQuestionsPage(0);
  }

  const onClickCleanSearch = (e) => {
    searchText[0] = {"value" : "T"};
    searchText[0] = {"fk_course_id" : 0};
    searchText[0] = {"fk_object_id" : 0};
    searchText[0] = {"year" : 0};
    searchText[0] = {"keyword" : ''};
    searchText[0] = {"fk_type_of_evaluation_id" : 0};
    onClickSearch();
  }

  const handlePageChange = (event, newPage) => {
    loadQuestions(newPage+1);
    setPage(newPage);
    searchQuestionsPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
      <div className={classes.root}>
          <UsersToolbar
              searchText={searchText}
              onClickSearch={onClickSearch}/>
        <div className={classes.content}>
          <TablePagination
              component="div"
              count={total}
              onChangePage={handlePageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[rowsPerPage]}/>

          {questions == null ?
              <LinearProgress color="secondary"    />
              :
              <Grid
                  container
                  spacing={1}>
                <Grid
                    item
                    md={12}
                    xs={12}>
                  <Table>
                    <TableBody>
                      {questions.map(question => (
                          <div style={{marginBottom: '20px'}}>
                            <QuestionCard
                                question={question}
                                setRefresh={setRefresh}
                                refresh={refresh}
                                id_course={searchText[0].fk_course_id}/>
                          </div>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid> }
          <TablePagination
              component="div"
              count={total}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[rowsPerPage]}/>
        </div>
      </div>
  );
};

QuestionTable.propTypes = {
  history: PropTypes.object
};

export default QuestionTable;
