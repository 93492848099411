import React, { useRef, useState, useEffect } from 'react';
import {Modal, Button, Form} from 'react-bootstrap'; // Make sure to install react-bootstrap if not already done
import './TextEditor.css';
import {Box, Tooltip} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import InfoMessage from "../InfoMessage";
import {toast} from "react-toastify";
import api from "../../services/api";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import RenderCodeLatex from "../RenderLatex/RenderCodeLatex";
import html2canvas from "html2canvas";
import ScreenCaptureModal from "../ScreenCaptureModal/ScreenCaptureModal";

// Componente para carregar o MathJa

const TextEditor = ({ id, value, onTextChange, minHeightEditor, disabled }) => {
    const editorRef = useRef(null);
    //const [content, setContent] = useState(value || '');
    const [savedRange, setSavedRange] = useState(null);
    const [fontSize, setFontSize] = useState('16px'); // Valor padrão
    const [fontFamily, setFontFamily] = useState(''); // Fonte padrão
    const [showSymbolsModal, setShowSymbolsModal] = useState(false); // State for the modal visibility
    const [symbols] = useState(['©', 'π', '√', '∞', 'Ω', '±', '≤', '≥', '≠', '∑', '∏',
        'Δ', 'α', 'β', 'γ', 'δ', 'ε', 'ζ', 'η', 'θ', 'ι', 'κ', 'λ', 'μ', 'ν',
        'ξ', 'ο', 'π', 'ρ', 'σ', 'τ', 'υ', 'φ', 'χ', 'ψ', 'ω',
        '∂', '∫', '∮', '∇', '∝', '∧', '∨', '⊥', '∠', '∈', '∉', '⊂', '⊆',
        '⊄', '⊈', '⊃', '⊇', '⊅', '⊉', '∈', '∉', '∋', '∏', '∑', '∞', '∂',
        '∫', '∮', '∧', '∨', '⇒', '⇔', '⇐', '⇒', '⇑', '⇓', '∀',
        '∃', '∅', '∇', '∈', '∉', '∋', '∈', '∉', '⊂', '⊃', '⊆', '⊇',
        '⊥', '∥', '≡', '≈', '≠', '≤', '≥', '⊥', '∠',
        '↔', '↕', '↖', '↗', '↘', '↙', '↰', '↲',
        '⇐', '⇒', '⇔', '⇕',]);
    const [showLatexModal, setShowLatexModal] = useState(false);
    const [latexCode, setLatexCode] = useState('');
    const [isInsideText, setIsInsideText] = useState(true);
    const [showImageModal, setShowImageModal] = useState(false);

    //image
    const [showMenuImage, setShowMenuImage] = useState(false);
    const [image, setImage] = useState(null);
    const [width, setWidth] = useState('');
    const [alignment, setAlignment] = useState('left');

    //link
    const [showLinkModal, setShowLinkModal] = useState(false);
    const [linkUrl, setLinkUrl] = useState('');
    const [linkText, setLinkText] = useState('');

    const [isLoaded, setIsLoaded] = useState(false); // Estado para verificar o carregamento

    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);

    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);

    const handleAddLink = () => {
        editorRef.current.focus();
        if (editorRef.current) {
            const selection = window.getSelection();

            if (selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);

                // Cria o elemento de link
                const linkElement = document.createElement('a');
                linkElement.href = linkUrl; // URL do link fornecido pelo usuário
                linkElement.textContent = linkText; // Texto do link fornecido pelo usuário
                linkElement.target = "_blank"; // Opcional: abre o link em uma nova aba
                linkElement.rel = "noopener noreferrer"; // Segurança adicional para links externos

                // Insere o link na posição atual do cursor
                range.deleteContents(); // Remove o conteúdo selecionado, se houver
                range.insertNode(linkElement); // Insere o elemento <a> na posição do cursor

                // Move o cursor para depois do link inserido
                range.setStartAfter(linkElement);
                range.setEndAfter(linkElement);
                selection.removeAllRanges();
                selection.addRange(range);

                // Atualiza o conteúdo do editor para refletir a mudança
                onTextChange(editorRef.current.innerHTML);
            }

            // Fechar o modal após adicionar o link
            setShowLinkModal(false);
        }
    };

    useEffect(() => {
        if (editorRef.current && isLoaded) {
            // Apenas define o innerHTML se for necessário
            if (editorRef.current.innerHTML !== value) {
                editorRef.current.innerHTML = value;
            }

            onTextChange(editorRef.current.innerHTML);
            restoreCursorPosition();
        }
    }, [isLoaded]);


    useEffect(() => {
        if (editorRef.current) {
            restoreCursorPosition();
        }
        if (editorRef.current && editorRef.current.innerHTML !== value) {
            editorRef.current.innerHTML = value;
        }
    }, [value]);


    const showResizeMenu = (img) => {
        setImage(img);
        const numericWidth = parseInt(img.style.width); // Pode ser necessário ajustar dependendo do formato
        if (!isNaN(numericWidth)) {
            setWidth(numericWidth);
        } else {
            setWidth(10);
        }
        if(img.style.float == 'left'){
            setAlignment('left');
        } else if(img.style.float == 'right'){
            setAlignment('right');
        } else {
            setAlignment('center');
        }
        setShowMenuImage(true);
    };

    const handleClick = (event) => {
        const element = event.target;
        if (element.tagName === 'IMG') {
            showResizeMenu(element);
        } else {
            setShowMenuImage(false);

            // Verifica se o elemento é um nó de texto ou contém texto
            if (element.nodeType === Node.TEXT_NODE || element.innerText) {
                const computedStyle = window.getComputedStyle(element);
                setFontFamily(computedStyle.fontFamily.replace(/['"]/g, ''));
                setFontSize(computedStyle.fontSize);
            }
        }
    };

    // Função para salvar a posição do cursor
    const saveCursorPosition = () => {
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
            setSavedRange(selection.getRangeAt(0).cloneRange());
        }
    };

    // Função para restaurar a posição do cursor
    const restoreCursorPosition = () => {
        if (savedRange && editorRef.current) {
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(savedRange);
        }
    };

    // Atualiza o conteúdo do editor e salva a posição do cursor
    const handleInput = () => {
        saveCursorPosition();

        const newContent = editorRef.current.innerHTML;
        onTextChange(newContent);
    };

    // Função para aplicar o tamanho da fonte
    const handleFontSizeChange = (event) => {
        const size = event.target.value;
        setFontSize(size);
        applyStyleToSelection('fontSize', size);
    };

    // Função para aplicar a fonte
    const handleFontFamilyChange = (event) => {
        const family = event.target.value;
        setFontFamily(family);
        applyStyleToSelection('fontFamily', family);
    };

    const applyStyleToSelection = (style, value) => {

        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);

            // Verifica se o intervalo está contido dentro de um único nó de texto
            if (range.startContainer === range.endContainer && range.startContainer.nodeType === Node.TEXT_NODE) {
                // Selecione um único nó de texto, aplique o estilo diretamente
                const span = document.createElement('span');
                span.style[style] = value;
                range.surroundContents(span);
            } else {
                // Para seleções complexas, divida o intervalo em duas partes e estilize o texto
                const fragment = range.cloneContents();
                const span = document.createElement('span');
                span.style[style] = value;
                span.appendChild(fragment);
                range.deleteContents();
                range.insertNode(span);
            }
        }
    };

    // Executa o comando de edição
    const execCommand = (command, value = null) => {
        document.execCommand(command, false, value);
        // Atualizar o estado com o conteúdo atual
        saveCursorPosition();
        onTextChange(editorRef.current.innerHTML);
    };

    // Função para abrir o modal de símbolos
    const openSymbolsModal = () => setShowSymbolsModal(true);

    const openImagesModal = () => setShowImageModal(true);

    const openLatexModal = () => setShowLatexModal(true);

    const handleSymbolClick = (symbol) => {
        editorRef.current.focus();
        if (editorRef.current) {
            const range = getCurrentSelectionAndRange();

            if (range) {
                adjustRangeIfNeeded(range, editorRef.current);
                const textNode = insertTextNode(range, symbol);
                repositionCursorAfterNode(textNode);

                saveCursorPosition(); // Função para salvar a posição do cursor
                onTextChange(editorRef.current.innerHTML); // Atualiza o estado de conteúdo
                setShowSymbolsModal(false); // Fecha o modal de símbolos
            } else {
                console.error("No active selection or cursor position to insert the symbol.");
            }
        }
    };

    // Função para obter a seleção e o range atuais
    const getCurrentSelectionAndRange = () => {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            return selection.getRangeAt(0);
        }
        return null;
    };

    // Função para ajustar o range se necessário
    const adjustRangeIfNeeded = (range, editorElement) => {
        if (
            range.startContainer.nodeType !== Node.TEXT_NODE &&
            range.startContainer !== editorElement
        ) {
            // Cria um novo range no final do editorRef
            range.setStart(editorElement, editorElement.childNodes.length);
            range.collapse(true);
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
        }
    };

    // Função para inserir o nó de texto (LaTeX ou qualquer outro texto)
    const insertTextNode = (range, text) => {
        const textNode = document.createTextNode(text);
        range.deleteContents();
        range.insertNode(textNode);
        return textNode;
    };

    // Função para reposicionar o cursor após a inserção
    const repositionCursorAfterNode = (textNode) => {
        const range = document.createRange();
        range.setStartAfter(textNode);
        range.setEndAfter(textNode);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
    };

    // Função principal para lidar com a inserção de LaTeX
    const handleInsertLatex = () => {
        const latexMarkup = isInsideText ? `$${latexCode}$` : `$$${latexCode}$$`;

        if (editorRef.current) {
            const range = getCurrentSelectionAndRange();

            if (range) {
                adjustRangeIfNeeded(range, editorRef.current);
                const textNode = insertTextNode(range, latexMarkup);
                repositionCursorAfterNode(textNode);

                saveCursorPosition(); // Função para salvar a posição do cursor
                onTextChange(editorRef.current.innerHTML); // Atualiza o estado de conteúdo
                setShowLatexModal(false); // Fecha o modal de inserção de LaTeX

                // Renderiza usando MathJax se necessário
                if (window.MathJax) {
                    window.MathJax.typesetPromise();
                }
            } else {
                console.error("No active selection or cursor position to insert LaTeX.");
            }
            setLatexCode("");
            setIsInsideText(true);
        }
    };

    const shouldRenderLatex = () => {
        const latexBlockRegex = /\$\$([\s\S]*?)\$\$/;
        const latexInlineRegex = /\$([\s\S]*?)\$/;
        return latexBlockRegex.test(value) || latexInlineRegex.test(value);
    };

    const insertTable = (rows, cols) => {
        if (editorRef.current) {
            const range = window.getSelection().getRangeAt(0);
            const table = document.createElement('table');
            table.style.width = '100%';
            table.style.borderCollapse = 'collapse';
            for (let i = 0; i < rows; i++) {
                const tr = document.createElement('tr');
                for (let j = 0; j < cols; j++) {
                    const td = document.createElement('td');
                    td.style.border = '1px solid black';
                    td.style.padding = '5px';
                    td.innerHTML = '&nbsp;';
                    tr.appendChild(td);
                }
                table.appendChild(tr);
            }
            range.deleteContents();
            range.insertNode(table);
            saveCursorPosition();
            onTextChange(editorRef.current.innerHTML);
        }
    };


    async function imageUploadHandler (blobInfo, success, failure, progress) {
        //insertImage('https://questione.s3-us-west-2.amazonaws.com/images/yoIPHjjP0hEXZaoaAsrfx0n9iiGUS486ZmngESPH.png');
        //setShowImageModal(false);
        //return ;
        try {
            const MAX_SIZE = 150000; // 150 KB

            // Verifica se o tamanho da imagem é maior que 150 KB
            if (blobInfo.blob().size > MAX_SIZE) {
                toast.error('A imagem deve ter no máximo 150kb.');
                return; // Para a execução da função
            }

            let formData = new FormData();
            formData.append('image', blobInfo.blob(), blobInfo.filename());

            if (blobInfo.blob().size > 150000) {
                failure('A imagem deve ter no máximo 150kb');
                return;
            }

            const response = await api.post('question/upload-image', formData);

            if(response.status === 200){
                if (editorRef.current) {
                    insertImage(response.data.url_image);
                }
                setShowImageModal(false);
                //success(response.data.url_image);
            } else if (response.status === 202) {
                failure('Ocorreu um erro ao realizar o upload');
            }
        } catch (error) {
            failure('Ocorreu um erro ao realizar o upload');
        }
    }

    const insertImage = (imageUrl) => {
        editorRef.current.focus();
        if (editorRef.current) {
            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                const img = document.createElement('img');
                img.src = imageUrl;
                img.alt = "Image"; // Optional: add alt text for accessibility
                //img.style.float = "left"; // Default alignment, can be changed
                img.style.display = "block";
                img.style.margin = "20px auto"; // Center the image
                img.style.width = "50%"; // Center the image
                range.deleteContents();
                range.insertNode(img);
                // Optionally place cursor after the image
                const br = document.createElement('br');
                range.insertNode(br);
                range.setStartAfter(br);
                range.setEndAfter(br);
                selection.removeAllRanges();
                selection.addRange(range);
                onTextChange(editorRef.current.innerHTML);
            } else {
                // Handle case where there's no active selection
                // Optionally add the image at the end of the editor
                const img = document.createElement('img');
                img.src = imageUrl;
                img.alt = "Image"; // Optional: add alt text for accessibility
                //img.style.float = "left"; // Default alignment, can be changed
                img.style.display = "block";
                img.style.margin = "20px auto"; // Center the image
                editorRef.current.appendChild(img);
                const br = document.createElement('br');
                editorRef.current.appendChild(br);
                onTextChange(editorRef.current.innerHTML);
            }
        }
    };


    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const blobInfo = {
                blob: () => file,
                filename: () => file.name
            };
            imageUploadHandler(blobInfo,
                null,
                (error) => {
                    alert(error);
                },
                (progress) => {
                    console.log('Upload progress:', progress);
                }
            );
        }
    };

    const handleWidthImageChange = (e) => {
        // Convertendo o valor do input range para número
        if (image) {
            const newWidth = Number(e.target.value);
            setWidth(newWidth);
            image.style.width = newWidth+"%";
            image.style.height = newWidth+"%";
            onTextChange(editorRef.current.innerHTML);
        }

    };

    const handleAlignmentImageClick = (alignment) => {
        // Convertendo o valor do input range para número
        if (image) {
            if(alignment == 'center'){
                image.style.float = '';
                image.style.display = "block";
                image.style.margin = "20px auto"; // Center the image
            } else {
                image.style.float = alignment;
                image.style.display = "";
                image.style.margin = "20px";
            }
            onTextChange(editorRef.current.innerHTML);
        }

    };

    const handleDeleteImage = () => {
        if (image) {
            image.parentNode.removeChild(image);
            onTextChange(editorRef.current.innerHTML); // Update the content state
            setImage(null);
            setShowMenuImage(false)
        }
    };

    // Função para renderizar o modal de símbolos
    const renderSymbolsModal = () => (
        <Modal show={showSymbolsModal} onHide={() => setShowSymbolsModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Selecione um símbolo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '3px' }}>
                    {symbols.map((symbol, index) => (
                        <Button key={index} onClick={() => handleSymbolClick(symbol)}>
                            {symbol}
                        </Button>
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    );

    const renderLinkModal = () => (
        <Modal
            show={showLinkModal}
            onHide={() => setShowLinkModal(false)}
            centered>
            <Modal.Header closeButton>
                <Modal.Title>Adicionar Link</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>URL do Link</Form.Label>
                    <Form.Control
                        type="url"
                        placeholder="https://exemplo.com"
                        value={linkUrl}
                        onChange={(e) => setLinkUrl(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mt-3">
                    <Form.Label>Texto do Link</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Texto que aparecerá no link"
                        value={linkText}
                        onChange={(e) => setLinkText(e.target.value)}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowLinkModal(false)}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={handleAddLink}>
                    Adicionar Link
                </Button>
            </Modal.Footer>
        </Modal>
    );

    const renderLatexModal = () => (
        <Modal
            show={showLatexModal}
            onHide={() => {
                setShowLatexModal(false);
                setLatexCode("");
                setIsInsideText(true);
            }}
            centered>
            <Modal.Header closeButton>
                <Modal.Title>Insira LaTeX</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="latexPlacement">
                        <Form.Label style={{fontWeight: 'bold'}}>Escolha o local do LaTeX </Form.Label>
                        <Box display="flex" justifyContent="row">
                            <Form.Check
                                type="radio"
                                label="Dentro do texto"
                                checked={isInsideText}
                                onChange={() => setIsInsideText(true)}
                            />
                            <Form.Check
                                type="radio"
                                style={{marginLeft: '10px'}}
                                label="Fora do texto"
                                checked={!isInsideText}
                                onChange={() => setIsInsideText(false)}
                            />
                        </Box>
                    </Form.Group>
                    <Form.Group controlId="latexCode" style={{marginTop: '10px'}}>
                        <Form.Label style={{fontWeight: 'bold'}}>Código LaTeX</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            value={latexCode}
                            onChange={(e) => setLatexCode(e.target.value)}
                            placeholder="Insira o código LaTeX"
                        />
                    </Form.Group>
                    {latexCode != "" &&
                        <div>
                            <Form.Label style={{fontWeight: 'bold', marginTop: '5px'}}>Visualização</Form.Label>
                            <RenderCodeLatex text={isInsideText ? "$"+latexCode+"$" : "$$"+latexCode+"$$"}/>
                        </div>}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setShowLatexModal(false);
                    setLatexCode("");
                    setIsInsideText(true);
                }
                }>
                    Fechar
                </Button>
                <Button variant="primary" onClick={handleInsertLatex}>
                    Inserir
                </Button>
            </Modal.Footer>
        </Modal>
    );

    const renderImageModal = () => (
        <Modal
            show={showImageModal}
            onHide={() => setShowImageModal(false)}
            centered>
            <Modal.Header closeButton>
                <Modal.Title>Selecione a Imagem</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <InfoMessage
                        text={"A imagem deve ter no máximo 150kb."} />
                    <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                    />
                </Form.Group>
            </Modal.Body>
        </Modal>
    );

    const [showModal, setShowModal] = useState(false);
    const [capturedImage, setCapturedImage] = useState(null);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleImageCaptured = async (imageUrl) => {
        setCapturedImage(imageUrl);
        handleClose(); // Fecha o modal após capturar a imagem

        // Converte a URL da imagem cortada em um Blob
        const response = await fetch(imageUrl);
        const blob = await response.blob();

        // Cria um objeto File a partir do Blob
        const file = new File([blob], 'captured-image.jpg', { type: 'image/jpeg' });

        // Chama a função de upload
        handleImageChange({ target: { files: [file] } });
    };


    return (
        <div>
            {!disabled?
            <div class="editor-container">
                <div class="editor-toolbar">
                    {/*<Tooltip title="Desfazer" arrow>
                        <button
                            className={`toolbar-button ${!canRedo ? 'disabled-button' : ''}`}
                            disabled={!canUndo}
                            onClick={() => handleUndo()}>
                            <i className="fas fa-undo"></i>
                        </button>
                    </Tooltip>

                    <Tooltip title="Refazer" arrow>
                        <button
                            className={`toolbar-button ${!canRedo ? 'disabled-button' : ''}`}
                            onClick={() => handleRedo()}
                            disabled={!canRedo}>
                            <i className="fas fa-redo"></i>
                        </button>
                    </Tooltip>*/}
                    <Tooltip title="Fonte" arrow>
                        <select onChange={handleFontFamilyChange} value={fontFamily}>
                            <option value="">Font Family</option>
                            <option value="Arial">Arial</option>
                            <option value="Courier New">Courier New</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Times New Roman">Times New Roman</option>
                            <option value="Verdana">Verdana</option>
                        </select>
                    </Tooltip>
                    <Tooltip title="Tamanho da fonte" arrow>
                        <select onChange={handleFontSizeChange} value={fontSize}>
                            <option value="10px">10px</option>
                            <option value="11px">11px</option>
                            <option value="12px">12px</option>
                            <option value="14px">14px</option>
                            <option value="16px">16px</option>
                            <option value="18px">18px</option>
                            <option value="20px">20px</option>
                            <option value="22px">22px</option>
                        </select>
                    </Tooltip>
                    <Tooltip title="Negrito" arrow>
                        <button title="Negrito" className="toolbar-button" onClick={() => execCommand('bold')}>
                            <i className="fas fa-bold"></i></button>
                    </Tooltip>
                    <Tooltip title="Itálico" arrow>
                        <button className="toolbar-button" onClick={() => execCommand('italic')}><i
                            className="fas fa-italic"></i></button>
                    </Tooltip>
                    <Tooltip title="Sublinhar" arrow>
                        <button className="toolbar-button" onClick={() => execCommand('underline')}><i
                            className="fas fa-underline"></i></button>
                    </Tooltip>
                    <Tooltip title="Alinhar à esquerda" arrow>
                        <button className="toolbar-button" onClick={() => execCommand('justifyLeft')}><i
                            className="fas fa-align-left"></i></button>
                    </Tooltip>
                    <Tooltip title="Centralizar" arrow>
                        <button className="toolbar-button" onClick={() => execCommand('justifyCenter')}>
                            <i className="fas fa-align-center"></i></button>
                    </Tooltip>
                    <Tooltip title="Alinhar à direita" arrow>
                        <button className="toolbar-button" onClick={() => execCommand('justifyRight')}>
                            <i className="fas fa-align-right"></i></button>
                    </Tooltip>
                    <Tooltip title="Justificar" arrow>
                        <button className="toolbar-button" onClick={() => execCommand('justifyFull')}>
                            <i className="fas fa-align-justify"></i></button>
                    </Tooltip>
                    <Tooltip title="Lista ordenada" arrow>
                        <button className="toolbar-button" onClick={() => execCommand('insertOrderedList')}><i
                            className="fas fa-list-ol"></i></button>
                    </Tooltip>
                    <Tooltip title="Lista não ordenada" arrow>
                        <button className="toolbar-button" onClick={() => execCommand('insertUnorderedList')}><i
                            className="fas fa-list-ul"></i></button>
                    </Tooltip>
                    <Tooltip title="Link" arrow>
                        <button className="toolbar-button"
                                onClick={() => setShowLinkModal(true)}>
                            <i className="fas fa-link"></i></button>
                    </Tooltip>
                    <Tooltip title="Sobrescrito" arrow>
                        <button className="toolbar-button" onClick={() => execCommand('superscript')}><i
                            className="fa-solid fa-superscript"></i></button>
                    </Tooltip>
                    <Tooltip title="Subscrito" arrow>
                        <button className="toolbar-button" onClick={() => execCommand('subscript')}><i
                            className="fa-solid fa-subscript"></i></button>
                    </Tooltip>
                    <Tooltip title="Símbolos" arrow>
                        <button className="toolbar-button" onClick={openSymbolsModal}><i
                            className="fa-solid fa-hashtag"></i></button>
                    </Tooltip>
                    <Tooltip title="Captura de tela" arrow>
                        <button className="toolbar-button" onClick={handleShow}>
                            <i className="fa-solid fa-camera"></i>
                        </button>
                    </Tooltip>
                    <ScreenCaptureModal
                        show={showModal}
                        handleClose={handleClose}
                        onImageCaptured={handleImageCaptured}
                    />
                    <Tooltip title="Imagem" arrow>
                        <button className="toolbar-button" onClick={openImagesModal}><i className="fas fa-image"></i>
                        </button>
                    </Tooltip>
                    <Tooltip title="LaTex" arrow>
                        <button className="toolbar-button" onClick={openLatexModal}><i
                            className="fas fa-square-root-alt"></i></button>
                    </Tooltip>
                    {/*<Tooltip title="Tabela" arrow>
                        <button className="toolbar-button" onClick={() => {
                            const rows = parseInt(prompt('Enter number of rows:', '2'), 10);
                            const cols = parseInt(prompt('Enter number of columns:', '2'), 10);
                            if (!isNaN(rows) && !isNaN(cols)) {
                                insertTable(rows, cols);
                            }
                        }}>
                            <i className="fas fa-table"></i>
                        </button>

                    </Tooltip>*/}

                </div>
                {showMenuImage && (
                    <div className="editor-toolbar"
                         style={{display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>

                        {/* Tamanho */}
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                            <label style={{ fontSize: '14px', fontWeight: 'bold' }}>Tamanho (%):</label>
                            <input
                                id="imageWidth"
                                type="range"
                                className="form-range"
                                min="5"
                                max="100"
                                value={width}
                                onChange={handleWidthImageChange}
                                style={{ width: '150px' }} // Ajuste a largura conforme necessário
                            />
                            <div
                                style={{
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    color: '#333',
                                }}
                            >
                                {width}%
                            </div>
                        </div>

                        {/* Alinhamento */}
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                            <label style={{ fontSize: '14px', fontWeight: 'bold', marginTop: '5px' }}>Alinhamento:</label>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <button
                                    onClick={() => handleAlignmentImageClick('left')}
                                    style={{
                                        padding: '8px',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        fontSize: '14px',
                                        backgroundColor: alignment === 'left' ? '#ddd' : 'transparent'
                                    }}
                                >
                                    <i className="fas fa-align-left"></i>
                                </button>
                                <button
                                    onClick={() => handleAlignmentImageClick('center')}
                                    style={{
                                        padding: '8px',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        fontSize: '14px',
                                        backgroundColor: alignment === 'center' ? '#ddd' : 'transparent'
                                    }}
                                >
                                    <i className="fas fa-align-center"></i>
                                </button>
                                <button
                                    onClick={() => handleAlignmentImageClick('right')}
                                    style={{
                                        padding: '8px',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        fontSize: '14px',
                                        backgroundColor: alignment === 'right' ? '#ddd' : 'transparent'
                                    }}
                                >
                                    <i className="fas fa-align-right"></i>
                                </button>
                            </div>
                        </div>

                        {/* Botões Ajustar e Cancelar */}
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <button
                                onClick={handleDeleteImage} // Função para deletar a imagem
                                style={{
                                    padding: '8px 12px',
                                    backgroundColor: '#e91e63', // Cor para o botão de deletar
                                    color: '#fff',
                                    border: 'none',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    fontSize: '14px'
                                }}
                            >
                                <i className="fas fa-trash-alt"></i> Deletar Imagem
                            </button>
                        </div>

                    </div>
                )}


                <div
                    id={id}
                    key={value.id}
                    class="editor-content"
                    contentEditable
                    ref={editorRef}
                    onInput={handleInput}
                    onClick={handleClick}
                    style={{ border: '1px solid white', minHeight: minHeightEditor ? minHeightEditor : '200px', padding: '10px' }}
                />
                {renderSymbolsModal()}
                {renderLatexModal()}
                {renderImageModal()}
                {renderLinkModal()}

                {shouldRenderLatex() && (
                    <div style={{marginTop: '2px', border: '0.5px solid #000'}}>
                        <Alert severity="info" style={{marginBottom: '10px'}}>{"O texto acima está sendo renderizado em código LaTeX:"}</Alert>
                        <RenderCodeLatex text={value}/>
                    </div>
                )}
            </div>
                :
                <div style={{marginTop: '10px'}}>
                    <RenderCodeLatex text={value}/>
                </div>
            }
        </div>
    );
};

export default TextEditor;
