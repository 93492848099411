import React, { useState, useEffect } from 'react';
import {Checkbox, FormControlLabel, Typography, Box, Link} from '@material-ui/core';
import useStyles from "../../style/style";
import SubjectIcon from "@material-ui/icons/Subject";
import QuestionText from "../QuestionText";
import QuestionAnswers from "../QuestionAnswers";
import RenderCodeLatex from "../RenderLatex/RenderCodeLatex";
import ReactHtmlParser from "react-html-parser";
import DecompositionDisplay from "../DecompositionDisplay/DecompositionDisplay";

const QuestionItemList = ({
                              question = null,
                              selectedQuestions = null,
                              handleSelectQuestion = null,
                              totalCharactersInText = 150,
                              showQuestionsDefault = false,
                              showCourse = true,
                              selectAll = false
                          }) => {

    const classesGeneral = useStyles();

    const [isChecked, setIsChecked] = useState(false);
    const [showFullText, setShowFullText] = useState(false);

    useEffect(() => {
        setShowFullText(showQuestionsDefault);
    }, []);

    const changeShowFullText = () => {
        setShowFullText(!showFullText);
    }

    const handleToggleQuestion = () => {
        const newIsChecked = !isChecked;
        setIsChecked(newIsChecked);

        // Chama a função fornecida pelo pai para atualizar a lista de IDs selecionados
        handleSelectQuestion(question.id);
    };

    const formatQuestionId = (id) => {
        return id.toString().padStart(6, '0');
    };

    const truncateHtmlText = (htmlText) => {
        // Remove tags HTML usando regex
        const plainText = htmlText.replace(/<\/?[^>]+(>|$)/g, "");

        // Verifica o comprimento do texto e aplica a truncagem se necessário
        if (plainText.length > totalCharactersInText) {
            return `${plainText.substring(0, totalCharactersInText)}...`;
        }

        return plainText;
    }

    return (
        <Box display="flex" flexDirection="column" position="relative" width="100%" >
            {question && (
                <div>
                    <Box display="flex" alignItems="center" justifyContent="space-between"  width="100%">
                        <Box display="flex" alignItems="center" flexGrow={1}>
                            {selectedQuestions != null && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isChecked}
                                            onChange={handleToggleQuestion}
                                        />
                                    }
                                    label={`Q${formatQuestionId(question.id)}`}
                                />
                            )}

                        </Box>
                        <Box flexShrink={0}>
                            <Link
                                component="button"
                                onClick={() => setShowFullText(!showFullText)}
                                style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Box display="flex" alignItems="center">
                                    <SubjectIcon />
                                    <Typography variant="body2" style={{ marginLeft: 4 }}>
                                        {!showFullText ? "Mostrar questão completa" : "Mostrar questão resumida"}
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                    </Box>
                    {!showFullText ? (
                        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                            { question.validated != 1 &&
                                <div className={classesGeneral.textRedInfo} style={{marginTop: '4px'}}>
                                    {"(Questão não finalizada)"}
                                </div>}
                            <Box display={"flex"} justifyContent={"row"}>
                                { (question.type_of_evaluation !== null) &&
                                    <div className={classesGeneral.subtitles} >
                                        {'Banca: '+question.type_of_evaluation.description}&nbsp;
                                    </div>
                                }
                                {showCourse && question.year && question.fk_type_of_evaluation_id !== '' &&
                                    <div className={classesGeneral.subtitles}>
                                        {"("+question.year+")"}
                                    </div>}
                            </Box>
                            {showCourse && question.course &&
                                <div className={classesGeneral.subtitles}>
                                    {'Área: '+question.course.description}
                                </div>}
                            {showCourse && question.knowledge_objects && question.knowledge_objects[0] &&
                                <div className={classesGeneral.paperTitleText}>
                                    {'Conteúdo(s): '} {question.knowledge_objects.map(item => (
                                         (item.description)+'. '
                                    ))}
                                </div>}

                            <div className={classesGeneral.subtitles}>
                                {"Texto base:"}
                            </div>
                            <div>
                                <RenderCodeLatex text={question.base_text}/>
                            </div>

                            <div className={classesGeneral.subtitles}>
                                {"Enunciado:"}
                            </div>
                            <div>
                                <RenderCodeLatex text={question.stem}/>
                            </div>
                            <QuestionAnswers
                                question={question}/>
                            <div style={{marginTop: '30px'}}></div>
                            {
                                <DecompositionDisplay decomposition={question.decomposition}/>
                            }
                        </div>
                    ) : (
                        <div>
                            <Box display="flex" flexDirection="column" position="relative" width="100%">
                                <QuestionText
                                    question={question}
                                    baseTextShow={true}/>
                            </Box>
                        </div>
                    )}
                </div>
            )}
        </Box>
    );
};

export default QuestionItemList;
