import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { copyToClipboard } from '../../../../../helpers/utils';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    IconButton,
    CardContent, Switch, Tooltip, FormControlLabel, Box, Paper, Link, AppBar, Toolbar, Dialog, useMediaQuery
} from '@material-ui/core';
import moment from 'moment';
import { toast } from 'react-toastify';
import {withRouter} from "react-router-dom";
import api from "../../../../../services/api";
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import ShareIcon from '@material-ui/icons/Share';
import {FormGroup} from "reactstrap";
import useStyles from "../../../../../style/style";
import GetAppIcon from "@material-ui/icons/GetApp";
import SettingsIcon from '@material-ui/icons/Settings';
import TooltipQuestione from "../../../../../components/TooltipQuestione";
import CloseIcon from "@material-ui/icons/Close";
import EvaluationQuestions from "../../../../../components/EvaluationQuestions/EvaluationQuestions";
import {generateEnemStylePdf} from "../../../../../utils/pdfGenerator";
import PhotoDialog from "../../../../../components/PhotoDialog/PhotoDialog";
import {truncateText} from "../../../../../utils/truncateText";
import DialogReleaseResults from "./DialogReleaseResults";
import DialogShareEvaluation from "./DialogShareEvaluation";

const useStylesLocal = makeStyles(() => ({
  root: {
      marginBottom: '10px'

  },
    head: {
        paddingBottom: 0,
        paddingTop: 6
    },
    chipred:{
        color: '#e57373',
    },
    chipgreen:{
        color: '#009688',
    },
    chip_brown:{
        color: '#795548',
    },
    chip_amber:{
        color: '#ffc107',
    },
    chipyellow:{
        color: '#fff176',
    },
    chipblue:{
        color: '#2196f3',
    },
  spacer: {
    flexGrow: 1
  },
    appBar: {
        position: 'relative',
        background: '#3a7cf7',
    },
}));

const EvaluationApplicationCard = props => {
    const { className, history, application, studentClassId, setRefresh, position, ...rest } = props;
    const [state, setState] = useState(0);
    const [evaluationApplication, setEvaluationApplication] = useState({});
    const [pdfData, setPdfData] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width:600px)');



    const classes = useStylesLocal();
    const classesGeneral = useStyles();

    useEffect(() => {

    }, [evaluationApplication]);

    useEffect(() => {
        setEvaluationApplication(application);
    }, []);

  const onEdit = (id) => {
      history.push(`/student-class/${studentClassId}/applications-evaluation/details/${id}`);
  }

  const results = (id) => {
      history.push(`/student-class/${studentClassId}/applications-evaluation/results/${id}`);
  }

    const copyLinkToClipboard = (id) => {
        copyToClipboard(window.location.origin + `/student-class/${studentClassId}/applications-evaluation/results/${id}`);
        toast.success('Link de respostas da aplicação copiado para a área de transferência');
    }

    async function onClickOpenDialogEnableApplication() {
        try {
            let url = 'evaluation/change-status-application/'+evaluationApplication.id;
            const response = await api.put(url);
            if (response.status === 202) {
                if(response.data.message){
                    toast.error(response.data.message);
                }
            } else {
                const new_evaluation = response.data[0];
                setEvaluationApplication(new_evaluation);

                toast.success('Modificado o status da aplicação.');
                setRefresh(Date.now());
            }
        } catch (error) {

        }
        setState(state+1);
    }

    async function downloadCSV(id){
        try {
            let url = `class/professor/get-csv/${id}`;


            const response = api.get(url,
                {
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/csv'
                    }
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'cvs_'+id+'.csv'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                })
                .catch();

            if (response.status === 202) {
                if(response.data.message){
                    toast.error(response.data.message);
                }
            } else {
                toast.success('Dados exportados.');
            }

        } catch (error) {

        }
    }

    const [openDialogQuestions, setOpenDialogQuestions] = React.useState(false);

    const handleCloseDialogQuestions = () => {
        setOpenDialogQuestions(false);
    }

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };
    const handlePdfGeneration = async () => {
        if(evaluationApplication.evaluation.totalQuestions === 0){
            toast.error('O simulado não possui questões.');
        } else if(evaluationApplication.evaluation.totalQuestions > 100) {
            toast.error('O simulado possui mais de 100 questões.');
        }
        try {
            // Gere o PDF e obtenha os bytes do PDF
            const pdfBytes = await generateEnemStylePdf({
                description: evaluationApplication.description,
                totalQuestion: evaluationApplication.evaluation.totalQuestions,
                code: evaluationApplication.id_application
            });

            // Crie um objeto Blob a partir dos bytes do PDF
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });

            // Crie uma URL de objeto para o Blob
            const url = URL.createObjectURL(blob);

            // Crie um elemento <a> para iniciar o download
            const link = document.createElement('a');
            link.href = url;
            link.download = 'cartao-resposta-'+evaluationApplication.id+'.pdf'; // Nome do arquivo a ser baixado

            // Simule um clique no link para iniciar o download
            document.body.appendChild(link);
            link.click();

            // Limpe a URL do objeto e remova o link
            URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            toast.error('Erro ao gerar o Cartão-Reposta.');
        }
    };

    //dialod release results
    const [openReleaseResultsDialog, setOpenReleaseResultsDialog] = useState(false);
    const [openShareEvaluationDialog, setOpenShareEvaluationDialog] = useState(false);

    const handleOpenShareEvaluationDialog = () => {
        setOpenShareEvaluationDialog(true);
    };

    // Função para fechar o diálogo
    const handleCloseShareEvaluationDialog = () => {
        setOpenShareEvaluationDialog(false);
    };

    async function handleSaveDataShareEvaluation(share_results){
        const data = {
            public_results: share_results.public_results,
            can_see_students: share_results.can_see_students,

            show_results: evaluationApplication.show_results,
            date_release_results: evaluationApplication.date_release_results,
            time_release_results: evaluationApplication.time_release_results,
            release_preview_question: evaluationApplication.release_preview_question,

            description: evaluationApplication.description,
            random_questions: evaluationApplication.random_questions,
            date_start: evaluationApplication.date_start,
            time_start: evaluationApplication.time_start,
            date_finish: evaluationApplication.date_finish,
            time_finish: evaluationApplication.time_finish,
            time_to_finalize: evaluationApplication.time_to_finalize,
            data_start_type: evaluationApplication.data_start_type
        }

        const response = await api.put('evaluation/applications/'+evaluationApplication.id, data);


        if (response.status === 202) {
            if(response.data.message){
                toast.error(response.data.message);
            } else if(response.data.errors[0].description){
                toast.error(response.data.errors[0].description);
            }
        } else if (response.status === 200) {
            toast.success('Aplicação atualizada.');
            setEvaluationApplication(response.data[0])
        }

    }

    const handleOpenReleaseResultsDialog = () => {
        setOpenReleaseResultsDialog(true);
    };


    const handleCloseReleaseResultsDialog = () => {
        setOpenReleaseResultsDialog(false);
    };

    // Função para salvar os dados enviados do componente DialogReleaseResults
    async function handleSaveDataReleaseResults(release_results){
        const data = {
            show_results: release_results.show_results,
            date_release_results: release_results.date_release_results,
            time_release_results: release_results.time_release_results,
            release_preview_question: release_results.release_preview_question,

            description: evaluationApplication.description,
            random_questions: evaluationApplication.random_questions,
            date_start: evaluationApplication.date_start,
            time_start: evaluationApplication.time_start,
            date_finish: evaluationApplication.date_finish,
            time_finish: evaluationApplication.time_finish,
            time_to_finalize: evaluationApplication.time_to_finalize,
            public_results: evaluationApplication.public_results,
            can_see_students: evaluationApplication.can_see_students,
            data_start_type: evaluationApplication.data_start_type
        }

        const response = await api.put('evaluation/applications/'+evaluationApplication.id, data);


        if (response.status === 202) {
            if(response.data.message){
                toast.error(response.data.message);
            } else if(response.data.errors[0].description){
                toast.error(response.data.errors[0].description);
            }
        } else if (response.status === 200) {
            toast.success('Aplicação atualizada.');
            setEvaluationApplication(response.data[0])

        }
    }

  return (
      <div>
          { evaluationApplication.id ?
              <div className={classes.content}>
              <Card
                  {...rest}
                  className={classes.root}>
                  <Paper className={evaluationApplication.evaluation.status == 2 || evaluationApplication.status == 0 ? classesGeneral.paperTitleGray :
                                         classesGeneral.paperTitleGreen}>
                      <Box display="flex">
                          <Box display="flex" sx={{ flexGrow: 1 }} justifyContent="flex-start">
                              <div className={classesGeneral.paperTitleTextBold}>
                                  {evaluationApplication.status == 1 ?
                                      <div>{'Simulado '+position+': '} {truncateText(evaluationApplication.description, 15, isSmallScreen)}</div>
                                          :
                                      <div>{'Simulado '+position+': '} {truncateText(evaluationApplication.description, 10, isSmallScreen)}</div>}
                              </div>
                          </Box>
                          <Box display="flex" justifyContent="flex-end">
                                  {(evaluationApplication.public_results === 1 || evaluationApplication.public_results === true)  && (
                                      <Tooltip title="Copiar link da avaliação">
                                          <IconButton
                                              aria-label="share"
                                              onClick={() => copyLinkToClipboard(evaluationApplication.id)}
                                              size="small">
                                              <ShareIcon />
                                          </IconButton>
                                      </Tooltip>
                                  )}

                                  {evaluationApplication.evaluation.status == 1 &&
                                      <TooltipQuestione description={'Clique para habilitar o simulado: '+evaluationApplication.description+'.'} position={'left-start'} content={
                                          <div style={{marginTop: '3px', marginLeft: '10px'}}>
                                              { evaluationApplication.status == 0
                                                  ? <FormGroup>
                                                      <FormControlLabel control={
                                                          <Switch
                                                              checked={evaluationApplication.status}
                                                              onChange={onClickOpenDialogEnableApplication}
                                                              color="primary"
                                                              name="checkedB"
                                                              size="small"
                                                              inputProps={{ 'aria-label': 'primary checkbox' }}
                                                          />
                                                      } label="Habilitar" />
                                                  </FormGroup> :
                                                  <Switch
                                                      checked={evaluationApplication.status}
                                                      onChange={onClickOpenDialogEnableApplication}
                                                      color="primary"
                                                      name="checkedB"
                                                      size="small"
                                                      inputProps={{ 'aria-label': 'primary checkbox' }}
                                                  />}
                                          </div>
                                      }/>  }


                              {!isSmallScreen &&
                                      <TooltipQuestione description={'Clique para visualizar as questões do simulado: '+evaluationApplication.description+'.'} position={'left-start'} content={
                                          <IconButton
                                              aria-label="copy"
                                              size="small"
                                              onClick={() => setOpenDialogQuestions(true)}
                                              style={{marginLeft: '10px'}}>
                                              <CalendarViewDayIcon />
                                          </IconButton>
                                      }/>}

                                  {evaluationApplication.evaluation.status == 1 &&
                                      <TooltipQuestione description={'Clique para configurar o simulado: '+evaluationApplication.description+'.'} position={'left-start'} content={
                                          <IconButton
                                              aria-label="copy"
                                              size="small"
                                              onClick={() => onEdit(evaluationApplication.id)}
                                              style={{marginLeft: '10px'}}>
                                              <SettingsIcon />
                                          </IconButton>
                                      }/>
                                  }
                          </Box>
                      </Box>
                  </Paper>
                  <CardContent>
                      <Box>
                          {evaluationApplication.totalAnswers > 0 ?
                              <Box display={'flex'}>
                                  <Link
                                      component="button"
                                      onClick={() => {
                                          results(evaluationApplication.id)
                                      }}
                                      className={clsx(classes.link, className)}>
                                      <div className={clsx(classesGeneral.paperTitleTextBold, classes.chipblue)}>
                                          {isSmallScreen ? 'Este simulado possui ' + evaluationApplication.totalAnswers + ' resposta(s).' :
                                              'Este simulado possui ' + evaluationApplication.totalAnswers + ' resposta(s). Clique aqui para visualizar o resultado.'}
                                      </div>
                                  </Link>
                                  <TooltipQuestione
                                      description={'Clique para realizar o download do arquivo de respostas no formato csv do simulado: ' + evaluationApplication.description + '.'}
                                      position={'bottom'} content={
                                      <IconButton
                                          aria-label="copy"
                                          size={"small"}
                                          onClick={() => downloadCSV(evaluationApplication.id)}
                                          onClick={() => downloadCSV(evaluationApplication.id)}
                                          style={{marginLeft: '10px', marginTop: '5px'}}>
                                          <GetAppIcon/>

                                      </IconButton>
                                  }/>
                              </Box> :
                              <div className={clsx(classesGeneral.paperTitleText)}>
                                  {'Não possui resposta(s). '}
                              </div>}

                      </Box>
                      <Link component="button" onClick={handlePdfGeneration}>
                          <div className={classesGeneral.paperTitleText}>
                              <i className="fa-solid fa-list-check" style={{marginRight: '5px'}}></i>
                              {'Imprimir Cartão-Resposta'}</div>
                      </Link>

                      {parseInt(localStorage.getItem("@Questione-id-user")) === 2 &&
                          <Link component="button"t onClick={handleOpenDialog}>
                              <div className={classesGeneral.paperTitleText} style={{marginLeft: isSmallScreen ? '0px' : '10px'}}>
                                  <i className="fa-solid fa-camera" style={{marginRight: '5px'}}></i>
                              {'Ler Cartão-Resposta'}</div>
                          </Link>}

                      <PhotoDialog open={dialogOpen} onClose={handleCloseDialog} id_application={evaluationApplication.id} />

                      <Box display="flex" alignItems="row" style={{marginTop: '10px'}}>
                          <TooltipQuestione description={'Clique aqui para liberar os resultados deste simulado para os alunos.'} position={'top-start'} content={
                              <Link component="button" onClick={handleOpenReleaseResultsDialog}>
                                  <div className={classesGeneral.paperTitleText}>
                                      <i className="fa-solid fa-rocket" style={{marginRight: '5px'}}>
                                  </i>
                                  {'Liberar resultados'}</div>
                              </Link>}
                          />
                          <DialogReleaseResults
                              open={openReleaseResultsDialog}
                              handleClose={handleCloseReleaseResultsDialog}
                              saveData={handleSaveDataReleaseResults}
                              show_results={evaluationApplication.show_results}
                              date_release_results={evaluationApplication.date_release_results}
                              time_release_results={evaluationApplication.time_release_results}
                              release_preview_question={evaluationApplication.release_preview_question}
                          />

                          <TooltipQuestione description={'Clique aqui para compartilhar os resultados deste simulado com outras pessoas.'} position={'top-start'} content={
                              <Link component="button" onClick={handleOpenShareEvaluationDialog} style={{marginLeft: '15px'}}>
                                  <div className={classesGeneral.paperTitleText}>
                                      <i className="fa-solid fa-share" style={{marginRight: '5px'}}>
                                  </i>
                                  {'Link de compartilhamento'}</div>
                              </Link>
                          }/>

                          <DialogShareEvaluation
                              open={openShareEvaluationDialog}
                              handleClose={handleCloseShareEvaluationDialog}
                              saveData={handleSaveDataShareEvaluation}
                              share_evaluation={evaluationApplication.public_results}
                              can_view_student_name={evaluationApplication.can_see_students}
                          />
                      </Box>


                      {!isSmallScreen &&
                          <Box display="flex" alignItems="row" style={{marginTop: '10px'}}>

                              {evaluationApplication.show_results == 1 &&
                                  <div className={clsx(classes.chip_brown, className)}
                                       style={{marginRight: '6px'}}>{'Os estudantes terão acesso ao resultado deste simulado.'}</div>
                              }

                              {evaluationApplication.release_preview_question == 1 &&
                                  <div className={clsx(classes.chip_brown, className)}
                                       style={{marginRight: '6px'}}>{'Os estudantes terão acesso as questões completas deste simulado.'}</div>
                              }

                              {evaluationApplication.random_questions == 1 &&
                                  <div className={clsx(classes.chip_brown, className)}
                                       style={{marginRight: '6px'}}>{'As questões serão apresentadas de forma aleatória.'}</div>
                              }

                              {evaluationApplication.date_start &&
                                  (evaluationApplication.data_start_type == 'DI' ?
                                      <div className={clsx(classes.chip_brown, className)}
                                           style={{marginRight: '6px'}}>
                                          <i className="fa-regular fa-clock" style={{marginRight: '10px'}}></i>
                                          {'Este simulado deve ser iniciado a partir do dia '
                                              + moment(evaluationApplication.date_start).utc().format('DD/MM/YYYY') + ' às '
                                              + evaluationApplication.time_start + '.'}</div>
                                      :
                                      <div className={clsx(classes.chip_brown, className)}
                                           style={{marginRight: '6px'}}>
                                          <i className="fa-regular fa-clock" style={{marginRight: '10px'}}></i>
                                          {'Este simulado deve ser iniciado no dia '
                                              + moment(evaluationApplication.date_start).utc().format('DD/MM/YYYY') + ' às '
                                              + evaluationApplication.time_start + '.'}</div>)
                              }

                              {((evaluationApplication.date_finish) &&
                                  <div className={clsx(classes.chip_brown, className)} style={{marginRight: '6px'}}><i
                                      className="fa-regular fa-calendar-xmark"
                                      style={{marginRight: '10px'}}></i>{'Este simulado deve ser finalizado até o dia ' + moment(evaluationApplication.date_finish).utc().format('DD/MM/YYYY') + ' às ' + evaluationApplication.time_finish + '.'}
                                  </div>)
                              }

                              {((evaluationApplication.time_to_finalize) &&
                                  <div className={clsx(classes.chip_brown, className)} style={{marginRight: '6px'}}><i
                                      className="fa-solid fa-hourglass-end"
                                      style={{marginRight: '10px'}}></i>{'Após iniciado, este simulado deve ser finalizado no tempo de ' + evaluationApplication.time_to_finalize + '.'}
                                  </div>)
                              }

                              {/*answer_head ?
                                      (!answer_head.finalized_at ?
                                          <div className={clsx(classes.chipblue, className)} style={{marginLeft: '4px'}}>{'| Iniciado'}</div> :
                                          <div className={clsx(classes.chipgreen, className)} style={{marginLeft: '4px'}}>{'| Finalizado'}</div>) :
                                      <div className={clsx(classes.chipred, className)} style={{marginLeft: '4px'}}>{'| Não iniciado'}</div>*/}

                          </Box>}
                      {localStorage.getItem("@Questione-id-user") != evaluationApplication.evaluation.user.id ?
                          <div className={classesGeneral.paperTitleText}>
                              {'Foi criado por meio da Avaliação '} <span
                              style={{fontWeight: 'bold'}}>{evaluationApplication.evaluation.id + ' - ' +
                              evaluationApplication.evaluation.description}</span>{' do Profersor ' + evaluationApplication.evaluation.user.name + "."}
                          </div>
                          :
                          <div className={classesGeneral.paperTitleText}>
                              {'Foi criado por meio da Avaliação: ' + evaluationApplication.evaluation.id + ' - ' +
                                  evaluationApplication.evaluation.description + "."}
                          </div>
                      }
                      <div className={classesGeneral.paperTitleText}>
                          {'Criado em: ' + moment(evaluationApplication.created_at).format('DD/MM/YYYY') + "."}
                      </div>
                      {evaluationApplication.evaluation.status == 2 &&
                          <div className={classesGeneral.textRedInfo} style={{marginTop: '4px'}}>
                              {'A avaliação deste simulado está arquivada.'}
                          </div>}

                  </CardContent>
                  <Dialog
                      open={openDialogQuestions}
                      fullScreen
                      onClose={handleCloseDialogQuestions}
                      aria-labelledby="simple-dialog-title">
                      <div>
                          <AppBar className={classes.appBar}>
                              <Toolbar>
                                  <IconButton edge="start" color="inherit" onClick={handleCloseDialogQuestions}
                                              aria-label="close">
                                  <CloseIcon />
                                      </IconButton>
                                      <div className={classesGeneral.titleList} style={{color: '#FFF', marginBottom: '15px'}}>
                                         Questões do simulado {evaluationApplication.description}
                                      </div>
                                  </Toolbar>
                              </AppBar>
                              <div style={{marginTop: '50px'}}>
                                  <EvaluationQuestions evaluationId={evaluationApplication.evaluation.id} hideDescription={true}/>
                              </div>
                          </div>
                  </Dialog>

              </Card>
              </div>
              : null }

      </div>



  );
};

EvaluationApplicationCard.propTypes = {
    className: PropTypes.string,
    application: PropTypes.object,
    history: PropTypes.object,
    setRefresh: PropTypes.object
};

export default withRouter(EvaluationApplicationCard);
