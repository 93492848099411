import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import EvaluationQuestionCard
    from "../../../../../Professor/Evaluation/EvaluationQuestions/components/EvaluationQuestionCard";

const OverviewQuestionModal = ({ show, order, handleClose, question }) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {'Detalhes da questão '+order}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{ maxHeight: '60vh', overflowY: 'auto' }} // Adjust maxHeight as needed
            >
                {question ? (
                    <EvaluationQuestionCard
                        question={question}
                        hasApplication={1}
                    />
                ) : (
                    <p>Nenhuma questão selecionada.</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default OverviewQuestionModal;
