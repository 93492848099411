import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const BarChart = ({ data, studentData, content, width = 400, height = 400 }) => {
    // Calcular a média da turma por conteúdo
    const calculateAverage = (data) => {
        const contentMap = new Map();
        data.forEach(item => {
            const { description } = item;
            if (!contentMap.has(description)) {
                contentMap.set(description, []);
            }
            contentMap.get(description).push(item.percentage_correct);
        });

        const averageMap = new Map();
        contentMap.forEach((percentages, description) => {
            const average = percentages.reduce((sum, percentage) => sum + percentage, 0) / percentages.length;
            averageMap.set(description, average);
        });

        return averageMap;
    };

    const averageMap = calculateAverage(data);

    // Preparar os dados para o gráfico
    const chartData = {
        labels: [...averageMap.keys()], // Usando as descrições como rótulos
        datasets: [
            {
                label: 'Média do Aluno',
                data: [...averageMap.keys()].map(description => {
                    const studentItem = studentData.content.find(item => item.description === description);
                    return studentItem ? studentItem.percentage_correct : 0;
                }),
                backgroundColor: 'rgba(54, 162, 235, 0.7)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
            {
                label: 'Média da Turma',
                data: [...averageMap.keys()].map(description => averageMap.get(description)),
                backgroundColor: 'rgba(255, 99, 132, 0.7)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
        ],
    };

    // Configurações do gráfico
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: content,
            },
        },
        indexAxis: 'y', // Configura para barras horizontais
        scales: {
            x: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Porcentagem',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Conteúdo',
                },
                ticks: {
                    font: {
                        size: 10, // Ajuste o tamanho da fonte conforme necessário
                    },
                },
            },
        },
    };

    return (
        <div style={{ width: `${width}%`, height: `${height}%` }}>
            <Bar data={chartData} options={options} />
        </div>
    );
};

export default BarChart;
